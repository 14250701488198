<template>
  <div
    class="hold-transition login-page theme-cyan-aside"
    :style="'background-image: url('+getImage('login-bg.jpg')+')'"
  >
    <div class="login-box">
      <a href="/"><img :src="getImage('logo.svg')" alt="Dotlines"></a>
      <div class="overlay text-center" v-if="is_loading">
        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
      </div>
      <form @submit.prevent="login()" method="POST" class="response-alert" novalidate>
        <div class="form-group mb-3">
          <label class="control-label"
          ><i class="fas fa-user"></i> Username</label
          >
          <input
            type="text"
            name="user_name"
            v-model.trim="user.username"
            class="form-control"
            placeholder="Username"
            v-validate="'required'"
            :class="{ 'has-error': errors.has('user_name') }"
          />
          <div class="help text-danger" v-show="errors.has('user_name')">
            {{ errors.first("user_name") }}
          </div>
        </div>

        <div class="form-group mb-3">
          <label class="control-label"
          ><i class="fas fa-lock"></i> Password</label
          >
          <input
            type="password"
            name="password"
            v-model.trim="user.password"
            class="form-control"
            placeholder="Password"
            v-validate="'required'"
            :class="{ 'has-error': errors.has('password') }"
          />
          <div class="help text-danger" v-show="errors.has('password')">
            {{ errors.first("password") }}
          </div>
        </div>
        <div class="row mb-2 align-items-center">
          <div class="col-6">
            <div class="icheck-primary">
              <input
                type="checkbox"
                v-model.trim="user.remember"
                id="remember"
              />
              <label for="remember"> Remember Me </label>
            </div>
          </div>
          <!-- /.col -->
          <div class="col-6 text-right forgot-pass">
            <router-link :to="{ name: 'ForgotPass' }">
              Forgot password <i class="fas fa-question-circle"></i>
            </router-link>
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-common btn-block"
          :disabled="is_disabled"
        >
          Login <i class="fas fa-arrow-right"></i>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import GuestLayout from '@/components/layouts/GuestLayoutComponent.vue'
import axios from 'axios'

export default {
  metaInfo: {
    title: 'Login | Dotlines'
  },
  components: {
    //
  },
  data: () => ({
    user: {},
    is_loading: false,
    is_disabled: false
  }),
  methods: {
    login: function (e) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const api_url = this.API_BASE_URL + '/auth/login'
          let token = ''
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this = this
          this.is_loading = true
          this.is_disabled = true
          axios({
            method: 'post',
            url: api_url,
            data: _this.user
          })
            .then(function (response) {
              if (response.status === 200) {
                token = response.data.token
                localStorage.setItem('authenticate', true)
                localStorage.setItem('token', token)
                // window.location = "/";
                _this.$router.push({name: 'Home'}) // for mobile app
              } else {
                // eslint-disable-next-line no-undef
                $('.response-alert').prepend(
                  // eslint-disable-next-line no-undef
                  $(
                    '<div class="alert alert-danger alert-dismissible fade show" role="alert">\n' +
                    "    Invalid username or password!\n" +
                    '    <button type="button" class="close" data-dismiss="alert" aria-label="Close">\n' +
                    '       <span aria-hidden="true">&times;</span>\n' +
                    "    </button>\n" +
                    "</div>"
                  )
                )
              }
            })
            .catch(function (error) {
              if (error.response) {
                // Request made and server responded
                /* console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers); */
                // eslint-disable-next-line no-undef
                $('.response-alert').prepend(
                  // eslint-disable-next-line no-undef
                  $(
                    '<div class="alert alert-danger alert-dismissible fade show" role="alert">\n' +
                    "    Invalid username or password!\n" +
                    '    <button type="button" class="close" data-dismiss="alert" aria-label="Close">\n' +
                    '       <span aria-hidden="true">&times;</span>\n' +
                    "    </button>\n" +
                    "</div>"
                  )
                )
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request)
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message)
              }
            })
            .finally(function () {
              _this.is_loading = false
              _this.is_disabled = false
              setTimeout(() => {
                // eslint-disable-next-line no-undef
                $('.alert').fadeOut('slow')
              }, 5000)
            })

          // console.warn(this.user)
          /* this.axios.post(baseUrl + 'login', this.user)
            .then((response) => {
              var data = response.data.data;
              localStorage.setItem('token', data.token);
              this.$router.push('/');
            })
            .catch((error) => {
              //
            }); */
        }
      })
    }
  },
  mounted: function () {
    localStorage.setItem('authenticate', false)
    localStorage.removeItem('token')
  },
  created () {
    this.$emit('update:layout', GuestLayout)
  }
}
</script>
